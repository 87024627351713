import { css } from '@emotion/react';
import * as React from 'react';
import Image from '../../atoms/Image/Image';

export default function BannerEstilo3(props) {
  const {
    imagenFondoDesktop,
    imagenFondoMobile,
    imagenSecundariaDesktop,
    imagenSecundariaMobile,
  } = props;

  const sectionCss = css`
    overflow: hidden;
  `;

  const imagenFondo = css`
    width: 100%;
    height: 98vh;
    min-height: calc(100vw * (calc(700 / var(--width_base))));
    object-position: left bottom !important;
    @media (max-width: 767px) {
      object-position: bottom !important;
    }
  `;

  const imagenSecundaria = css`
    position: absolute;
    top: 50%;
    bottom: 0;
    z-index: 10;
    left: 0;
    right: 0;
    width: calc(100vw * (calc(200 / var(--width_base))));
    height: calc(100vw * (calc(200 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 767px) {
      left: 10%;
      right: initial;
      margin-left: initial;
      margin-right: initial;
      width: calc(100vw * (calc(300 / var(--width_base))));
    }
    & img {
      object-fit: contain !important;
    }
  `;

  return (
    <>
      <div css={sectionCss} className="only-desk">
        {imagenFondoDesktop && (
          <Image styleCss={imagenFondo} imagen={imagenFondoDesktop} />
        )}
        {imagenSecundariaDesktop && (
          <Image
            styleContentCss={imagenSecundaria}
            imagen={imagenSecundariaDesktop}
          />
        )}
      </div>
      <div css={sectionCss} className="only-mov">
        {imagenFondoMobile && (
          <Image styleCss={imagenFondo} imagen={imagenFondoMobile} />
        )}
        {imagenSecundariaMobile && (
          <Image
            styleContentCss={imagenSecundaria}
            imagen={imagenSecundariaMobile}
          />
        )}
      </div>
    </>
  );
}
